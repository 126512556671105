import { database } from './index.mjs';
import {
  ref,
  child,
  get,
  onValue,
  set,
  push,
  update,
  remove,
} from 'firebase/database';
import medicinesData from '@/Mock/medicinesData';
import insuranceData from '@/Mock/insuranceData';
import betaMedicinesData from '@/Mock/betaMedicinesData';
import betaInsuranceData from '@/Mock/betaInsuranceData';

const betaMedicinesRef = ref(database, 'originalData/betaMedicines');
const medicinesRef = ref(database, 'originalData/medicines');
const treeRef = ref(database, 'originalData/tree');
const insuranceRef = ref(database, 'originalData/insurance');
const ICDSearchRef = ref(database, 'originalData/ICDSearch');
const medicineSearchRef = ref(database, 'originalData/medicineSearch');
const dataVersionRef = ref(database, 'originalData/dataVersion');
const relatedWordsRef = ref(database, 'originalData/relatedWords');

const autocompleteRef = ref(database, 'originalData/autocomplete');

const userRef = ref(database, 'users');
const feedbackRef = ref(database, 'feedbacks');
const purchaseRef = ref(database, 'purchase');

const fetchDataVersion = async (callback) => {
  const snapshot = await get(dataVersionRef);

  if (snapshot.exists()) {
    const dataVersion = await snapshot.val();
    // const medicinesData = medicinesStructure;
    return dataVersion;
  } else {
    console.log('empty');
  }
};

const fetchBetaMedicinesData = async (callback) => {
  if (process.env.NODE_ENV === 'development') {
    return betaMedicinesData;
  } else {
    const snapshot = await get(betaMedicinesRef);

    if (snapshot.exists()) {
      const betaMedicinesData = await snapshot.val();
      // const medicinesData = medicinesStructure;
      return betaMedicinesData;
    } else {
      console.log('empty');
    }
  }
};
const fetchMedicinesData = async (callback) => {
  if (process.env.NODE_ENV === 'development') {
    return medicinesData;
  } else {
    const snapshot = await get(medicinesRef);

    if (snapshot.exists()) {
      const medicinesData = await snapshot.val();
      // const medicinesData = medicinesStructure;
      return medicinesData;
    } else {
      console.log('empty');
    }
  }
};

const fetchTreeData = async (callback) => {
  const snapshot = await get(treeRef);

  if (snapshot.exists()) {
    const treeData = await snapshot.val();
    // const treeData = treeStructure;
    return treeData;
  } else {
    console.log('empty');
  }
};

const fetchBetaInsuranceData = async (callback) => {
  if (process.env.NODE_ENV === 'development') {
    return betaInsuranceData;
  } else {
    const snapshot = await get(insuranceRef);

    if (snapshot.exists()) {
      const betaInsuranceData = await snapshot.val();

      return betaInsuranceData;
    } else {
      console.log('empty');
    }
  }
};

const fetchInsuranceData = async (callback) => {
  if (process.env.NODE_ENV === 'development') {
    return insuranceData;
  } else {
    const snapshot = await get(insuranceRef);

    if (snapshot.exists()) {
      const insuranceData = await snapshot.val();

      return insuranceData;
    } else {
      console.log('empty');
    }
  }
};

const fetchUserData = async (uid) => {
  const snapshot = await get(child(userRef, uid));

  if (snapshot.exists()) {
    const userData = await snapshot.val();
    return userData;
  } else {
    console.log('empty');
  }
};

const fetchRelatedWordsData = async (uid) => {
  const snapshot = await get(relatedWordsRef);

  if (snapshot.exists()) {
    const realtedWords = await snapshot.val();
    return realtedWords;
  } else {
    console.log('empty');
  }
};

const updateUserData = async (uid, section, data) => {
  try {
    await set(child(userRef, `${uid}/${section}`), data);
  } catch (error) {
    console.log(error);
    return new Error('SAVE FAILED');
  }
};

// const updateUserPreference = async (uid, userPreference) => {
//   console.log('firebase', uid, userPreference);
//   try {
//     await set(child(userRef, `${uid}/userPreference`), userPreference);
//   } catch (error) {
//     console.log(error);
//   }
// };

const sendFeedback = async (uid, data) => {
  try {
    const newPostKey = push(feedbackRef).key;
    const updates = {};

    updates['/feedbacks/' + newPostKey] = data;
    updates['users/' + uid + '/feedbacks/' + newPostKey] = data;

    return await update(ref(database), updates);
  } catch (error) {
    console.log(error);
  }
};

const sendVisitorFeedback = async (data) => {
  try {
    await push(feedbackRef, data);
  } catch (error) {
    console.log(error);
  }
};

const updateFeedback = async (key, data) => {
  try {
    set(child(feedbackRef, `${key}`), data);
  } catch (error) {
    console.log(error);
  }
};

const fetchFeedback = async (callback) => {
  const unsubscribe = await onValue(feedbackRef, (snapshot) => {
    const data = snapshot.val() || {};
    callback(data);
  });
  return unsubscribe;
};

const deleteFeedback = async (key) => {
  try {
    remove(child(feedbackRef, `${key}`));
  } catch (error) {
    console.log(error);
  }
};

const sendPurchaseInfo = async (data) => {
  try {
    const newPostRef = push(purchaseRef);

    set(newPostRef, data);
  } catch (error) {
    console.log(error);
  }
};

const fetchPurchaseInfo = async () => {
  const snapshot = await get(purchaseRef);

  if (snapshot.exists()) {
    const purchaseInfoData = await snapshot.val();
    return purchaseInfoData;
  } else {
    console.log('empty');
  }
};

const fetchMedicineAutocomplete = async () => {
  const snapshot = await get(child(medicineSearchRef, 'autocomplete'));

  if (snapshot.exists()) {
    const autocompleteData = await snapshot.val();

    return autocompleteData;
  } else {
    console.log('empty');
  }
};

// const fetchICDData = async () => {
//   const snapshot = await get(ICDSearchRef);

//   if (snapshot.exists()) {
//     const ICDData = await snapshot.val();
//     return ICDData;
//   } else {
//     console.log('empty');
//   }
// };

// function writeUserData() {
//   remove(ref(database, '/originalData/medicineSearchRef'));
// }

// writeUserData();

export {
  database,
  medicinesRef,
  treeRef,
  fetchDataVersion,
  fetchBetaMedicinesData,
  fetchMedicinesData,
  fetchTreeData,
  fetchBetaInsuranceData,
  fetchInsuranceData,
  fetchUserData,
  fetchRelatedWordsData,
  updateUserData,
  sendFeedback,
  sendVisitorFeedback,
  updateFeedback,
  fetchFeedback,
  deleteFeedback,
  sendPurchaseInfo,
  fetchPurchaseInfo,
  fetchMedicineAutocomplete,
  // fetchICDData,
};
