import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { generateKey } from '@/utils/generateKey';
import { updateUserData } from '@/libs/firebase/database';

export const updateMyNote = createAsyncThunk(
  'medicines/updateMyNote',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const uid = state.control.auth.uid;
    const updatedMyNote = {
      ...state.medicines.myNote,
      ...payload,
    };

    await updateUserData(uid, 'myNote', updatedMyNote);

    return updatedMyNote;
  },
);

export const saveMyNote = createAsyncThunk(
  'medicines/saveMyNote',
  async (payload, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const state = getState();
    const uid = state.control.auth.uid;

    const result = await updateUserData(uid, 'myNote', payload);

    if (result instanceof Error) {
      return rejectWithValue('failed');
    }

    return payload;
  },
);

export const medicinesSlice = createSlice({
  name: 'medicines',
  initialState: {
    trees: [],
    panes: {
      activeKey: '',
      data: [],
    },
    medicines: [],
    betaMedicines: [],
    insurances: [],
    myNote: {},
    isSaving: false,
  },
  reducers: {
    loadUserData: (state, action) => {
      for (let key in state) {
        if (action.payload?.[key]) {
          state[key] = action.payload?.[key];
        }
      }

      return state;
    },
    loadTrees: (state, action) => {
      state.trees = action.payload;
    },
    loadBetaMedicines: (state, action) => {
      state.betaMedicines = action.payload;
    },
    loadMedicines: (state, action) => {
      state.medicines = action.payload;
    },
    loadInsurances: (state, action) => {
      state.insurances = action.payload;
    },
    addPane: (state, action) => {
      const key = generateKey(action.payload.section);
      state.panes.data = state.panes.data.filter(
        (pane) => pane.title !== action.payload.title,
      );
      state.panes.data.push({ ...action.payload, key });
      state.panes.activeKey = key;
    },
    updatePanes: (state, action) => {
      state.panes.data = [...action.payload.data];
      state.panes.activeKey = action.payload.activeKey;
    },
    // updateMyNote: (state, action) => {
    //   state.myNote = { ...state.myNote, ...action.payload };
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(updateMyNote.fulfilled, (state, action) => {
      state.myNote = action.payload;
    });
    builder.addCase(saveMyNote.pending, (state, action) => {
      state.isSaving = true;
    });
    builder.addCase(saveMyNote.fulfilled, (state, action) => {
      state.myNote = action.payload;
      state.isSaving = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  loadUserData,
  loadTrees,
  loadBetaMedicines,
  loadMedicines,
  loadInsurances,
  addPane,
  updatePanes,
  // updateMyNote,
} = medicinesSlice.actions;

export default medicinesSlice.reducer;
