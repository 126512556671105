import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useBetaMode = () => {
  const [isBetaMode, setBetaMode] = useState(false);
  const isAdmin = useSelector((state) => state.control.auth.role) === 'ADMIN';
  const betaEnabled = useSelector(
    (state) => state.control.userPreference.betaEnabled,
  );

  useEffect(() => {
    if (isAdmin) {
      if (betaEnabled) {
        setBetaMode(true);
      } else {
        setBetaMode(false);
      }
    } else {
      setBetaMode(false);
    }
  });
  // console.log('Beta mode enabled', isBetaMode);

  return isBetaMode;
};
