import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { notification } from 'antd';

// Create a reference to the file we want to download
export const downloadInsuranceDetail = ({ key }) => {
  const storage = getStorage();
  const pathReference = ref(storage, `/insuranceDetail/${key}.pdf`);
  // Get the download URL
  getDownloadURL(pathReference)
    .then((url) => {
      // Insert url into an <img> tag to "download"
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const blob = xhr.response;
        const href = URL.createObjectURL(blob);
        const a = Object.assign(document.createElement('a'), {
          href,
          style: 'display:none',
          download: `${key}.pdf`,
        });
        document.body.appendChild(a);

        a.click();
        URL.revokeObjectURL(href);
        a.remove();
      };
      xhr.open('GET', url);
      xhr.send();
    })
    .catch((error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      notification.info({
        message: '此藥物無附件',
        description: '此藥物無附件',
        duration: 2,
      });
      switch (error.code) {
        case 'storage/object-not-found':
          // File doesn't exist
          break;
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;

        // ...

        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          break;
      }
    });
};

export const downloadPackageInsert = ({ key }) => {
  const storage = getStorage();
  const pathReference = ref(storage, `/packageInsert/${key}.pdf`);
  // Get the download URL
  getDownloadURL(pathReference)
    .then((url) => {
      // Insert url into an <img> tag to "download"
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const blob = xhr.response;
        const href = URL.createObjectURL(blob);
        const a = Object.assign(document.createElement('a'), {
          href,
          style: 'display:none',
          download: `${key}.pdf`,
        });
        document.body.appendChild(a);

        a.click();
        URL.revokeObjectURL(href);
        a.remove();
      };
      xhr.open('GET', url);
      xhr.send();
    })
    .catch((error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      notification.info({
        message: '此藥物無仿單',
        description: '此藥物無仿單',
        duration: 2,
      });
      switch (error.code) {
        case 'storage/object-not-found':
          // File doesn't exist
          break;
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;

        // ...

        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          break;
      }
    });
};

export const downloadNoteCode = ({ key }) => {
  const storage = getStorage();
  const pathReference = ref(storage, `/treatment/noteCode/${key}.pdf`);
  // Get the download URL
  getDownloadURL(pathReference)
    .then((url) => {
      // Insert url into an <img> tag to "download"
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const blob = xhr.response;
        const href = URL.createObjectURL(blob);
        const a = Object.assign(document.createElement('a'), {
          href,
          style: 'display:none',
          download: `${key}.pdf`,
        });
        document.body.appendChild(a);

        a.click();
        URL.revokeObjectURL(href);
        a.remove();
      };
      xhr.open('GET', url);
      xhr.send();
    })
    .catch((error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      notification.info({
        message: '此備註代碼無附件',
        description: '此備註代碼無附件',
        duration: 2,
      });
      switch (error.code) {
        case 'storage/object-not-found':
          // File doesn't exist
          break;
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;

        // ...

        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          break;
      }
    });
};

export const downloadICD = () => {
  const storage = getStorage();
  const pathReference = ref(storage, `/ICD/ICD-10.json`);
  // Get the download URL
  const ICDData = getDownloadURL(pathReference)
    .then((url) => {
      return fetch(url);
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/object-not-found':
          // File doesn't exist
          break;
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;

        // ...

        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          break;
      }
    });
  return ICDData;
};

export const downloadTreatment = ({ file }) => {
  const storage = getStorage();
  const pathReference = ref(storage, `/treatment/${file}.json`);
  // Get the download URL
  const treatmentData = getDownloadURL(pathReference)
    .then((url) => {
      return fetch(url);
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/object-not-found':
          // File doesn't exist
          break;
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;

        // ...

        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          break;
      }
    });
  return treatmentData;
};
